exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wrapper__3S2wuWHM {\n  display: none; }\n\n.isLoading__9lDgdUlF {\n  height: 100%;\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n  align-items: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  position: absolute;\n  z-index: 1500; }\n\n.backGround__1FP1jifV {\n  background-color: rgba(0, 0, 0, 0.2); }\n\n.notAbsolute__2lOAvdr5 {\n  position: static; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "wrapper__3S2wuWHM",
	"isLoading": "isLoading__9lDgdUlF",
	"backGround": "backGround__1FP1jifV",
	"notAbsolute": "notAbsolute__2lOAvdr5"
};