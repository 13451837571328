exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".overlay__2ct-XouS {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border: 2px solid red; }\n\n.newUserModal__1sBPd3ID .cardModal__3YWDglPd {\n  background-color: #369bff;\n  color: #eeeeee;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n  max-width: 500px;\n  max-height: 500px;\n  padding: 20px;\n  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px; }\n  .newUserModal__1sBPd3ID .cardModal__3YWDglPd .secondaryText__2HhpczMt {\n    color: #c4c4c4; }\n\n.newUserModal__1sBPd3ID .imReadyButton__DyKJHZgE {\n  bottom: 100%;\n  left: 70%;\n  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  color: #369bff;\n  background-color: #e0e0e0;\n  position: relative;\n  overflow: hidden; }\n  .newUserModal__1sBPd3ID .imReadyButton__DyKJHZgE:hover {\n    background-color: #eeeeee; }\n", ""]);

// Exports
exports.locals = {
	"overlay": "overlay__2ct-XouS",
	"newUserModal": "newUserModal__1sBPd3ID",
	"cardModal": "cardModal__3YWDglPd",
	"secondaryText": "secondaryText__2HhpczMt",
	"imReadyButton": "imReadyButton__DyKJHZgE"
};