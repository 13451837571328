import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {changePreference, logOut} from '@redux/reducers/auth/actions';
import {Button, FormControlLabel, Switch} from '@material-ui/core';

import PowerOff from '@assets/svg/powerOff.svg?tag';
import CheckMark from '@assets/svg/checkmark.svg?tag';
import styles from './styles.m.scss';

interface IProps {
  history: any;
  profile: IProfile;
  changePreference: typeof changePreference;
  logOut: typeof logOut;
}

class Profile extends React.Component<IProps> {

  userLogout = () => {
    this.props.logOut();
    this.props.history.push('/auth');
  };

  handleTableViewToggle = () => {
    this.props.changePreference('assetTableView');
  };

  render() {
    const { profile } = this.props;

    const permissions = profile.company.owner && (
      <div className={styles.permissionToCategories}>
        <CheckMark />
        <p className={styles.descPermission}>
          Owner has permission to all categories
        </p>
      </div>
    );
    return (
        <>
            <div className={styles.profile}>
                <div className={classNames(styles.profileBlock, styles.personalInfo)}>
                    <img
                        src={profile.data.photoUrl}
                        alt="Profile photo"
                        className={styles.profilePhoto}
                    />
                    <div className={styles.profileInfo}>
                        <h2 className={styles.profileName}>{profile.data.displayName}</h2>
                        <p className={styles.profileEmail}>{profile.data.email}</p>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={styles.logout}
                            onClick={this.userLogout}
                        >
                            <PowerOff />
                            Log Out
                        </Button>
                    </div>
                </div>
                <div
                    className={classNames(styles.profileBlock, styles.blockOrganization)}
                >
                    <div className={styles.headerOrganization}>
                        <p className={styles.blockTitle}>Organization</p>
                    </div>
                    <h1 className={styles.companyName}>{profile.company.companyName}</h1>
                </div>
                <div
                    className={classNames(styles.profileBlock, styles.blockPermission)}
                >
                    <p className={styles.blockTitle}>Editorial Permissions</p>
                    {permissions}
                </div>
            </div>
            <div className={styles.profileOptions}>
                <span>Preferences:</span>
                <br/>
                <FormControlLabel
                    control={
                        (
                            <Switch
                                checked={profile.preferences?.assetTableView}
                                onChange={this.handleTableViewToggle}
                                name="checkedB"
                                color="primary"
                            />
                        )
                    }
                    label="Table view of assets"
                />
            </div>
        </>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  profile: state.auth.profile
});

const actions = {
  changePreference,
  logOut,
};

export default compose(withRouter, connect(mapStateToProps, actions))(Profile);
