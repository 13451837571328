import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { RouteComponentProps } from 'react-router-dom';
import { Tabs, Tab, Button } from '@material-ui/core';
import Profile from './components/profile';
import BillingInfo from './components/billing-info';
import UserList from './components/user-list';
import ModalWindow from '@components/modal-window';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import { closeAccount, fetchProfile } from '@redux/reducers/auth/actions';
import { fetchProjects } from '@redux/reducers/projects/actions';
import { fetchCategories } from '@redux/reducers/categories/actions';
import { AccountModalWindows } from '@components/modal-window/modalWindowsTypes';

import Cross from '@assets/svg/cross.svg?tag';
import styles from './styles.m.scss';
import FileTagList from '@pages/settings/components/file-tag-list';

interface IProps extends RouteComponentProps {
  modalWindows: any;
  isOwner: boolean;
  closeAccount: typeof closeAccount;
  fetchCategories: typeof fetchCategories;
  fetchProfile: typeof fetchProfile;
  fetchProjects: typeof fetchProjects;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
}

function Settings(props: IProps) {
  const { modalWindows, isOwner } = props;
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(
    false
  );

  useEffect(() => {
    props.fetchProfile();
    props.fetchCategories();
    props.fetchProjects();
  }, []);

  const isModalWindowOpen = (type: string): boolean => {
    return !!(modalWindows
      && modalWindows[type]
      && modalWindows[type] === 'auth');
  };

  const closeAccountRequest = (value: boolean) => {
    setOpenConfirmModal(false);
    if (value) {
      props.closeAccount();
    }
  };

  const closeModal = () => {
    props.closeModalWindow(AccountModalWindows.successModal);
  };

  const handleChangeTabs = (_event: React.ChangeEvent<{}>, value: any) => {
    props.history.push(value);
  };

  const getContentSettings = (): React.ReactElement => {
    switch (location.pathname) {
      case '/settings/profile': return <Profile />;
      case '/settings/billing-info': return <BillingInfo />;
      case '/settings/user-list': return <UserList />;
      case '/settings/tags': return <FileTagList />;
    }
  };

  const content = getContentSettings();

  const confirmMessage = {
    action: ', close it!',
    text: (
        <>
          <p>You will not be able to recover your Neatly dashboard!</p>
          {isOwner && <p>This will also delete your company and cancel your subscription.</p>}
        </>
    ),
    title: (
        <h1>
          Are you sure you want to close your
          <br />
          <span>Neatly</span> account?
        </h1>
    )
  };

  const successMessage = {
    action: 'Account Closed!',
    text: <p>Please check your email for confirmation close your account</p>,
    title: <h1>Account Closed!</h1>
  };

  const tabBillingInfoForAdmin = isOwner && <Tab label="Billing Info" value="/settings/billing-info" />;
  const tabUserListForAdmin = isOwner && (
    <Tab label="User List" value="/settings/user-list" />
  );
  const tabTagManagementForAdmin = isOwner && (
      <Tab label="Admin Panel" value="/settings/tags" />
  );

  return (
    <>
      <Scrollbars autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
        <div className={styles.wrapper}>
          <div className={styles.headerSettings}>
            <Tabs
              className={styles.settingsTabs}
              value={props.location.pathname}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTabs}
            >
              <Tab label="My Profile" value="/settings/profile" />
              {tabBillingInfoForAdmin}
              {tabUserListForAdmin}
              {tabTagManagementForAdmin}
            </Tabs>
            <Button
              variant="text"
              startIcon={<Cross />}
              className={styles.closeAccountBtn}
              onClick={() => setOpenConfirmModal(true)}
            >
              Close account
            </Button>
          </div>
          {content}
        </div>
      </Scrollbars>
      <ModalWindow
        type="confirm"
        message={confirmMessage}
        open={openConfirmModal}
        onClose={closeAccountRequest}
      />
      <ModalWindow
        type="success"
        message={successMessage}
        open={isModalWindowOpen(AccountModalWindows.successModal)}
        onClose={closeModal}
      />
    </>
  );
}

const mapStateToProps = (state: IStore) => ({
  isOwner: state.auth.profile.company.owner,
  modalWindows: state.modalWindowState.modalWindows,
});

const actions = {
  closeAccount,
  closeModalWindow,
  fetchCategories,
  fetchProfile,
  fetchProjects,
  openModalWindow
};

export default compose(withRouter, connect(mapStateToProps, actions))(Settings);
