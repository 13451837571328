import * as React from 'react';
import {
    Button,
    Chip,
    Menu,
    MenuItem,
    styled,
    Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Component} from 'react';
import {connect} from 'react-redux';
import {fetchTags, updateFile} from '@redux/reducers/files/actions';
import styles from './styles.m.scss';
import {FileTagModal} from '@components/modal-window/modalWindowsTypes';
import AddIcon from '@material-ui/icons/Add';
import {openModalWindow} from '@redux/reducers/modal-window/actions';

interface IOwnProps {
    file: IFile;
}

interface IReduxProps {
    allCompanyFileTags: IFileTag[];
    updateFile: typeof updateFile;
    fetchTags: typeof fetchTags;
    tagsLoading: boolean;
    companyId: string;
    isOwner: boolean;
    openModalWindow: typeof openModalWindow;
}

interface IProps extends IReduxProps, IOwnProps {}

interface IState {
    addTags: boolean;
    anchorEl: HTMLElement;
    tags: IFileTag[];
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        },
    },
};
const DEFINE_NEW_TAG_LABEL = '+Define New Tag';

class FileTags extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            addTags: false,
            anchorEl: null,
            tags: props.file.tags,
        };
    }

    isAlreadyAdded = (fileTag: IFileTag) => {
       return this.state.tags.map((tag) => tag.name).includes(fileTag.name);
    };

    handleSelectOnChange = (newValue: string) => {
        if (newValue === undefined || newValue == null || newValue === DEFINE_NEW_TAG_LABEL) {
            return;
        }
        const { updateFile: updateFileProp, file } = this.props;

        const uniqueTags: IFileTag[] = [...this.state.tags];

        const newTag = this.props.allCompanyFileTags.find((tag) => tag.name === newValue);
        if (newTag === undefined) {
            return;
        }
        uniqueTags.push(newTag);
        updateFileProp(file, file.name, uniqueTags);
        this.setState({
            addTags: false,
            tags: uniqueTags,
        });
    };

    handleDelete = (fileTag: IFileTag) => () => {
        const { updateFile: updateFileProp, file } = this.props;
        const { tags } = this.state;

        const updatedTags = tags.filter((tag) => tag.name !== fileTag.name);

        updateFileProp(file, file.name, updatedTags);

        this.setState((prevState) => ({
            addTags: false,
            tags: prevState.tags.filter((tag) => tag.name !== fileTag.name)
        }));
    };

    handleAddTagsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { fetchTags: fetchTagsProp, companyId } = this.props;
        fetchTagsProp();
        this.setState({addTags: true, anchorEl: event.currentTarget});
    };

    handleSelectOnClose = () => {
        this.setState({addTags: false});
    };

    render() {
        const { addTags } = this.state;

        return (
            <div className={styles.fileTags}>
                <>
                    {this.state.tags?.map((data, index) => (
                        <ListItem key={this.props.file.tags.length + index} style={{ marginRight: '4px' }}>
                            <Tooltip title={data.description}>
                                <Chip
                                    label={data.name}
                                    style={{ backgroundColor: data.color, cursor: 'pointer' }}
                                    onDelete={this.handleDelete(data)}
                                    size={'small'}
                                />
                            </Tooltip>
                        </ListItem>
                    ))}
                    {this.state.tags?.length < 5 && (
                        <>
                            <ListItem key="addNewTag">
                                <div className={styles.newFileTag}>
                                    <button
                                        id={'add-new-tag-button'}
                                        onClick={this.handleAddTagsClick}
                                        className={styles.addTagBtn}
                                    >
                                        Add new tag
                                    </button>
                                    {addTags && (
                                        <Menu
                                            id="demo-controlled-open-select"
                                            open={addTags}
                                            keepMounted={true}
                                            onClose={this.handleSelectOnClose}
                                            anchorEl={this.state.anchorEl}
                                        >
                                            {
                                                [...this.props.allCompanyFileTags
                                                    .filter((fileTag) => !this.isAlreadyAdded(fileTag))
                                                    .map((fileTag) => (
                                                        <MenuItem
                                                            key={fileTag.name}
                                                            value={fileTag.name}
                                                            style={{backgroundColor: fileTag.color}}
                                                            onClick={() => this.handleSelectOnChange(fileTag.name)}
                                                        >
                                                            {fileTag.name}
                                                        </MenuItem>
                                                    )),
                                                    (
                                                        <Button
                                                            key={'default-neatly-unique-1'}
                                                            style={{color: 'gray'}}
                                                            onClick={
                                                                () =>
                                                                    this.props.openModalWindow(FileTagModal.addNewModal, 'fileTag')
                                                            }
                                                        >
                                                            {DEFINE_NEW_TAG_LABEL}
                                                        </Button>
                                                    )
                                                ]
                                            }
                                        </Menu>
                                    )}
                                </div>
                            </ListItem>
                        </>
                    )}
                </>
            </div>
        );
    }
}

const mapStateToProps = (state: IStore) => ({
    allCompanyFileTags: state.filesState.tags,
    companyId: state.auth.profile.company.companyId,
    isOwner: state.auth.profile.company.owner,
    tagsLoading: state.filesState.fileTagsLoader,
});

const actions = {
    fetchTags,
    openModalWindow,
    updateFile,
};

export default connect(mapStateToProps, actions)(FileTags);
