exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".root__3ZuO7DfM {\n  text-align: right; }\n  .root__3ZuO7DfM .wrapper__34ij87Ph {\n    margin-left: auto; }\n    .root__3ZuO7DfM .wrapper__34ij87Ph .addNewTagBtn__1hF1AR-3 {\n      line-height: 10px;\n      color: #e0e0e0;\n      background-color: #484747;\n      -webkit-transition: height 0.3s, width 0.3s;\n      -o-transition: height 0.3s, width 0.3s;\n      transition: height 0.3s, width 0.3s; }\n      .root__3ZuO7DfM .wrapper__34ij87Ph .addNewTagBtn__1hF1AR-3:hover {\n        -webkit-transform: scale(1.02) translateY(2px);\n        -ms-transform: scale(1.02) translateY(2px);\n        transform: scale(1.02) translateY(2px);\n        -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); }\n\n.wrapper__34ij87Ph .table__3hi2Dwdj .isLoading__3VaixDmx {\n  background-color: rgba(0, 0, 0, 0.2);\n  pointer-events: none;\n  -webkit-filter: blur(0.7px);\n  filter: blur(0.7px); }\n\n.wrapper__34ij87Ph .table__3hi2Dwdj .tableBody__2mD3pEgL .loader__3mY7Y6Ys {\n  position: absolute;\n  top: 40%;\n  left: 50%; }\n", ""]);

// Exports
exports.locals = {
	"root": "root__3ZuO7DfM",
	"wrapper": "wrapper__34ij87Ph",
	"addNewTagBtn": "addNewTagBtn__1hF1AR-3",
	"table": "table__3hi2Dwdj",
	"isLoading": "isLoading__3VaixDmx",
	"tableBody": "tableBody__2mD3pEgL",
	"loader": "loader__3mY7Y6Ys"
};