import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
  createByUrl,
  createDocFile,
  createFromDrive,
  clearActiveGroup,
  deleteFiles,
  fetchFiles,
  updateFile,
  setActiveIds,
  toggleNewFileWindow,
  uploadFile,
  changeToGroup,
  getFileById,
  handleSelectFromDriveModal
} from '@redux/reducers/files/actions';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import { isAdminOrModerator } from '@services/user-role';
import { createSection } from '@redux/reducers/sections/actions';
import { requestTokenUpdate } from '@redux/reducers/auth/actions';
import { FilesModalWindows } from '@components/modal-window/modalWindowsTypes';
import { getFilesBySectionId, getFilesByGroupId } from '@redux/reducers/files';
import ContentTable from './content-table';
import Plausible from 'plausible-tracker';
import {setActiveWizardStep, setNewWizardStep} from '@redux/reducers/wizard/actions';

interface IReduxProps {
  accessToken: string;
  activeFileFromState: IFile;
  activeCategory: ICategory;
  activeFileTagFilter: IFileTag[];
  activeGroup: IFile;
  activeIds: IActiveFileIds;
  sections: ISection[];
  wizardState: IWizardState;
  setActiveIds: typeof setActiveIds;
  createFromDrive: typeof createFromDrive;
  createDocFile: typeof createDocFile;
  createByUrl: typeof createByUrl;
  createSection: typeof createSection;
  clearActiveGroup: typeof clearActiveGroup;
  requestTokenUpdate: typeof requestTokenUpdate;
  toggleNewFileWindow: typeof toggleNewFileWindow;
  handleSelectFromDriveModal: typeof handleSelectFromDriveModal;
  deleteFiles: typeof deleteFiles;
  fetchFiles: typeof fetchFiles;
  uploadFile: typeof uploadFile;
  updateFile: typeof updateFile;
  changeToGroup: typeof changeToGroup;
  getFileById: typeof getFileById;
  newFileId: string;
  errors: string[];
  isAssetTableView: boolean;
  isLoading: boolean;
  isLoadingOnFetch: boolean;
  isSelectFromDrive: boolean;
  files: IFile[];
  filesInGroup: IFile[];
  remoteCount: number;

  modalWindows: any;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
  setNewWizardStep: typeof setNewWizardStep;
  setActiveWizardStep: typeof setActiveWizardStep;
}

interface IOwnProps {
  sectionId: string;
  groupName?: string;
  groupDescription?: string;
  isContentGroupFilesModal?: boolean;
  handleEmptySection?: () => void;
  setInvalidGroupTitle?: () => void;
}

interface IProps extends IReduxProps, IOwnProps {}

interface IState {
  activeFile: IFile | null;
  addFileDropdownEl: null | HTMLButtonElement;
  description: string;
  isOpenAddFile: boolean;
  isOpenAddGroupFiles: boolean;
  name: string;
  page: number;
  rowsPerPage: number;
  titleInvalid: boolean;
  moreOptionEl: null | HTMLElement;
  typeFile: GoogleDocumentType;
  typeInput: FileInputType;
  selectedFilesIds: string[];
  shareFileId: string;
  canEditSelected: boolean;
  isConfirmModalOpen: boolean;
  isConfirmSelectedModalOpen: boolean;
  isShareModalOpen: boolean;
}

const defaultTitle: string = '2. Add your first file';
const defaultDescription: string = 'Add Description';
const plausible = Plausible({
  domain: `${process.env.DOMAIN}`
});

class Files extends Component<IProps, IState> {
  state: IState = {
    activeFile: null,
    addFileDropdownEl: null,
    canEditSelected: false,
    description: '',
    isConfirmModalOpen: false,
    isConfirmSelectedModalOpen: false,
    isOpenAddFile: false,
    isOpenAddGroupFiles: false,
    isShareModalOpen: false,
    moreOptionEl: null,
    name: '',
    page: 0,
    rowsPerPage: 5,
    selectedFilesIds: [],
    shareFileId: '',
    titleInvalid: false,
    typeFile: '',
    typeInput: ''
  };

  _descriptionInputRef = createRef<HTMLInputElement>();
  _nameInputRef = createRef<HTMLInputElement>();
  _openRef = createRef<HTMLAnchorElement>();

  componentDidUpdate = () => {
    if (this.props.newFileId && this.props.newFileId.length > 0) {
      const file = this.props.isContentGroupFilesModal
        ? this.props.filesInGroup &&
          this.props.filesInGroup.find((f) => f.id === this.props.newFileId)
        : this.props.files &&
          this.props.files.find((f) => f.id === this.props.newFileId);
      if (file && file.data && file.data.fileType !== 'link') {
        this.openFile(file);
      }
    }
  };

  openFile = (file: IFile) => {
    const data = file.data || null;
    if (data) {
      if (data.fileType === 'drive') {
        this.props.getFileById(file.id, true);
      } else if (data.fileType === 'link') {
        this._openRef.current.href = data.fileUrl;
        this._openRef.current.click();
      } else {
        this._openRef.current.href = `/docs/${file.id}`;
        this._openRef.current.click();
      }
    }
    this.props.toggleNewFileWindow('');
  };

  openGroup = (file: IFile) => {
    this.props.fetchFiles(file.sectionId, file.id, file.filesCount, 0);
    this.handleModalAddGroupFiles(true);
  };

  openSectionItem = (item: IFile) => {
    plausible.trackEvent('Open File', {
      props: {
        type: item.data?.fileType,
        variation: item.isGroup ? 'group' : 'normal'
      }
    });
    if (item.isGroup) {
      this.openGroup(item);
    } else {
      this.openFile(item);
    }
  };

  handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const { sectionId, activeFileTagFilter } = this.props;
    const { rowsPerPage } = this.state;
    const offset = rowsPerPage * newPage;
    this.props.fetchFiles(sectionId, '', rowsPerPage, offset, activeFileTagFilter.map((tag) => tag._id));
    this.setState({
      page: newPage
    });
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { sectionId, remoteCount } = this.props;
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (newRowsPerPage === -1) {
      this.props.fetchFiles(sectionId, '', Number.MAX_SAFE_INTEGER, 0);
    } else {
      this.props.fetchFiles(sectionId, '', newRowsPerPage, 0);
    }
    this.setState({
      page: 0,
      rowsPerPage: newRowsPerPage
    });
  };

  handleError = () => {
    this.props.requestTokenUpdate(true);
  };

  isLengthValid = (value: string) => {
    const check = value.trim();
    if (check.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  createFileDoc = (name: string, type: GoogleDocumentType) => {
    const { sectionId, groupName, groupDescription, activeGroup } = this.props;
    if (sectionId !== '') {
      plausible.trackEvent('Add File', {
        props: {
          type
        }
      });
      this.props.createDocFile(
        sectionId,
        type,
        name,
        activeGroup && activeGroup.id,
        groupName,
        groupDescription
      );
    }
    this.handleOpenModalAddFile(false);
    this.handleCloseAddFile();
  };

  handleSaveValueFromModal = (name: string, url: string) => {
    const { sectionId, groupName, activeGroup, groupDescription } = this.props;
    this.props.createByUrl(
      sectionId,
      name,
      url,
      activeGroup && activeGroup.id,
      groupName,
      groupDescription
    );
    this.setState({ isOpenAddFile: false });
  };

  handleFileSave = (file: File) => {
    const { activeGroup, sectionId, groupName, groupDescription } = this.props;
    this.props.uploadFile(
      sectionId,
      file,
      activeGroup && activeGroup.id,
      groupName,
      groupDescription
    );
    this.setState({ isOpenAddFile: false });
  };

  mapPickedFileType = (type: string) => {
    switch (type) {
      case 'doc':
        return 'document';
      case 'spread':
        return 'spreadsheet';
      case 'pres':
        return 'presentation';
      default:
        return 'drive';
    }
  };

  handleOpenModalAddFile = (value: boolean | IPickerResponse) => {
    const {
      sectionId,
      groupName,
      isContentGroupFilesModal,
      activeGroup,
      groupDescription,
      wizardState
    } = this.props;
    if (wizardState.isWizardActive) {
      this.props.setActiveWizardStep(3);
    }
    if (sectionId === '') {
      this.props.handleEmptySection();
    } else if (typeof value !== 'boolean' && value.action) {
      if (value.action === 'picked' && value.docs) {
        const docs: IFilePicked[] = value.docs.map((doc) => ({
          description: doc.description,
          fileId: doc.id,
          groupDescription:
            activeGroup && activeGroup.id ? null : groupDescription,
          groupId: activeGroup && activeGroup.id ? activeGroup.id : null,
          groupTitle: activeGroup && activeGroup.id ? null : groupName,
          name: doc.name,
          sectionId,
          type: this.mapPickedFileType(doc.serviceId)
        }));
        const wrappedFiles: IFilesPicked = {
          files: docs,
          groupDescription:
            activeGroup && activeGroup.id ? null : groupDescription,
          groupId: activeGroup && activeGroup.id ? activeGroup.id : null,
          groupTitle: activeGroup && activeGroup.id ? null : groupName,
          sectionId
        };
        this.props.createFromDrive(wrappedFiles);
      }
      this.setState({ isOpenAddFile: false });
    } else if (typeof value === 'boolean') {
      if (
        isContentGroupFilesModal &&
        groupName.length < 2 &&
        (activeGroup ? activeGroup.name === '' : true)
      ) {
        this.props.setInvalidGroupTitle();
      } else {
        if (value && this.props.isSelectFromDrive) {
          this.props.handleSelectFromDriveModal(false);
        }
        this.setState({ isOpenAddFile: value, addFileDropdownEl: null });
      }
    }
  };

  handleUpdateFile = (name: string, desc: string) => {
    const { activeFile } = this.state;
    if (this.isLengthValid(name)) {
      if (name !== activeFile.name || desc !== activeFile.description) {
        this.props.updateFile(activeFile, name, undefined);
      }
      this.setState({
        activeFile: null,
        description: '',
        name: '',
        titleInvalid: false,
        typeInput: ''
      });
    } else {
      this.setState({ titleInvalid: true });
    }
  };

  handleDeleteFile = (isForAll?: boolean) => {
    this.handleCloseMoreOption();
    if (isForAll) {
      this.setState({ isConfirmSelectedModalOpen: true });
    } else {
      this.setState({ isConfirmModalOpen: true });
    }
  };

  deleteFiles = (value: boolean, selected?: boolean) => {
    const { page } = this.state;
    const { sectionId, activeGroup } = this.props;
    this.setState({
      isConfirmModalOpen: false,
      isConfirmSelectedModalOpen: false
    });
    if (!value) {
      return;
    }

    if (selected) {
      this.setState({ selectedFilesIds: [] });
      this.props.deleteFiles(
          sectionId,
          activeGroup && activeGroup.id,
          this.state.selectedFilesIds
      );
      plausible.trackEvent('Delete File', {
        props: {
          variation: 'selected'
        }
      });
    } else {
      plausible.trackEvent('Delete File', {
        props: {
          variation: 'non-selected'
        }
      });
      this.props.deleteFiles(
          undefined,
          undefined,
          [this.state.activeFile.id]
      );
      if (page !== 0 && !((page * this.state.rowsPerPage - 1) % 5)) {
        this.setState({ page: page - 1 });
      }
    }
  };

  handleChangeToGroup = () => {
    const { activeIds } = this.props;
    this.handleCloseMoreOption();
    this.removeIdFromSelectedFiles(activeIds.filesIds[0]);
    this.props.changeToGroup(activeIds.filesIds[0]);
  };

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { typeInput } = this.state;
    if (
      typeInput === 'name' &&
      e.currentTarget.value.replace(/\s+/g, '').length < 31
    ) {
      this.setState({ name: e.currentTarget.value });
    } else if (
      typeInput === 'tags' &&
      e.currentTarget.value.replace(/\s+/g, '').length < 51
    ) {
      this.setState({ description: e.currentTarget.value });
    }
    this.setState({ titleInvalid: false });
  };

  handleRename = (event?: React.KeyboardEvent<HTMLInputElement>) => {
    const { typeInput, name, description } = this.state;
    if (event && event.key === 'Enter') {
      if (typeInput === 'name') {
        this.handleUpdateFile(this._nameInputRef.current.value, description);
      } else if (typeInput === 'tags') {
        this.handleUpdateFile(name, this._descriptionInputRef.current.value);
      }
    }
  };

  toggleInput = (file: IFile, type: FileInputType) => {
    if (
      file.canEdit ||
      isAdminOrModerator(this.props.activeCategory?.userRole)
    ) {
      this.setState({
        activeFile: file,
        description: file.description,
        name: file.name,
        titleInvalid: false,
        typeInput: type
      });
    }
  };

  handleInputBlur = () => {
    const { typeInput, name, description } = this.state;
    if (typeInput === 'name') {
      this.handleUpdateFile(this._nameInputRef.current.value, description);
    } else if (typeInput === 'tags') {
      this.handleUpdateFile(name, this._descriptionInputRef.current.value);
    }
  };

  discardChange = () => {
    this.setState({
      activeFile: null,
      description: '',
      name: '',
      titleInvalid: false,
      typeInput: ''
    });
  };

  isNameSectionValid = (name: string) => {
    if (this.props.sections.map((e) => e.name).indexOf(name) !== -1) {
      return false;
    } else {
      return true;
    }
  };

  getNameSection = (): string => {
    const defaultName = '1. Add section title';
    if (this.isNameSectionValid(defaultName)) {
      return defaultName;
    } else {
      let i = 1;
      while (!this.isNameSectionValid(`${defaultName} (${i})`)) {
        ++i;
      }
      return `${defaultName} (${i})`;
    }
  };

  removeIdFromSelectedFiles = (fileId?: string) => {
    const { activeFileFromState } = this.props;
    const { selectedFilesIds } = this.state;
    const selectedId = fileId
      ? fileId
      : activeFileFromState && activeFileFromState.id;
    this.setState({
      selectedFilesIds: selectedFilesIds.filter((id) => id !== selectedId)
    });
  };

  onClickAddMoreFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { isContentGroupFilesModal } = this.props;
    if (isContentGroupFilesModal) {
      this.handleOpenModalAddFile(true);
    } else {
      this.handleOpenAddFile(event);
    }
  };

  handleModalAddGroupFiles = (value: boolean) => {
    const { sectionId } = this.props;
    const { selectedFilesIds } = this.state;
    this.handleCloseMoreOption();
    this.handleCloseAddFile();
    if (sectionId === '') {
      this.props.handleEmptySection();
    } else {
      this.setState({ isOpenAddGroupFiles: value });
      if (value && this.props.isSelectFromDrive) {
        this.props.handleSelectFromDriveModal(false);
      }
      if (!value) {
        this.props.clearActiveGroup();
        if (this.state.rowsPerPage * this.state.page === selectedFilesIds.length - 1) {
          this.handleSelectAllFiles(true);
        }
      }
    }
  };

  handleOpenOptions = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: IFile
  ) => {
    this.setState({
      activeFile: file,
      moreOptionEl: event.currentTarget,
      typeInput: ''
    });
    this.props.setActiveIds(file.sectionId, [file.id], file.groupId);
  };

  handleCloseMoreOption = () => {
    this.setState({ moreOptionEl: null });
  };

  handleCloseAddFile = () => {
    this.setState({ addFileDropdownEl: null });
  };

  handleOpenAddFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ addFileDropdownEl: event.currentTarget });
  };

  openFileByClick = (file: IFile) => {
    const { typeInput } = this.state;
    if (typeInput === '') {
      this.openFile(file);
    }
  };

  handleSelectAllFiles = (isSelected: boolean) => {
    const { files, filesInGroup, isContentGroupFilesModal } = this.props;
    if (isSelected) {
      let ids;
      let canEditSelected;
      if (isContentGroupFilesModal) {
        ids = filesInGroup.map((f) => f.id);
        canEditSelected = filesInGroup.every((f) => f.canEdit);
      } else {
        ids = files.map((f) => f.id);
        canEditSelected = files.every((f) => f.canEdit);
      }
      this.setState({ selectedFilesIds: ids, canEditSelected });
    } else {
      this.setState({ selectedFilesIds: [] });
    }
  };

  handleSelectFile = (file: IFile, isSelected: boolean) => {
    this.setState((prevState) => {
      const { selectedFilesIds } = prevState;
      if (isSelected) {
        if (selectedFilesIds.length) {
          const final = [...selectedFilesIds];
          final.push(file.id);
          return { selectedFilesIds: final, canEditSelected: file.canEdit };
        } else {
          return { selectedFilesIds: [file.id], canEditSelected: file.canEdit };
        }
      } else if (selectedFilesIds.length) {
        const final = selectedFilesIds.filter((id) => id !== file.id);
        return { selectedFilesIds: final, canEditSelected: false };
      }
    });
  };

  isModalWindowOpen = (type: string): boolean => {
    return !!(
      this.props.modalWindows &&
      this.props.modalWindows[type] &&
      this.props.modalWindows[type] === this.props.sectionId
    );
  };

  closeFilesSuccessModal = (isAll: boolean) => {
    const { filesInGroup } = this.props;
    this.removeIdFromSelectedFiles();
    if (!filesInGroup) {
      this.handleModalAddGroupFiles(false);
    }
    if (isAll) {
      this.props.closeModalWindow(FilesModalWindows.successSelectedModal);
    } else {
      this.props.closeModalWindow(FilesModalWindows.successModal);
    }
  };

  handleShareClose = (value: boolean, fileId?: string) => {
    this.handleCloseMoreOption();
    if (value && fileId) {
      this.setState({ shareFileId: fileId });
    } else {
      this.setState({ shareFileId: '' });
    }
    this.setState({ isShareModalOpen: value });
  };

  render() {
    const {
      activeFile,
      addFileDropdownEl,
      typeFile,
      name,
      isOpenAddFile,
      isOpenAddGroupFiles,
      moreOptionEl,
      page,
      rowsPerPage,
      titleInvalid,
      typeInput,
      shareFileId,
      selectedFilesIds,
      canEditSelected,
      isConfirmModalOpen,
      isConfirmSelectedModalOpen,
      isShareModalOpen
    } = this.state;
    const {
      activeFileFromState,
      activeCategory,
      sectionId,
      files,
      filesInGroup,
      accessToken,
      remoteCount,
      isLoadingOnFetch,
      isLoading,
      isContentGroupFilesModal,
      activeGroup
    } = this.props;

    return (
      <ContentTable
        activeFile={activeFile}
        activeFileFromState={activeFileFromState}
        activeCategory={activeCategory}
        accessToken={accessToken}
        addFileDropdownEl={addFileDropdownEl}
        sectionId={sectionId}
        shareFileId={shareFileId}
        files={files}
        filesInGroup={filesInGroup}
        page={page}
        rowsPerPage={rowsPerPage}
        typeInput={typeInput}
        titleInvalid={titleInvalid}
        name={name}
        moreOptionEl={moreOptionEl}
        remoteCount={remoteCount}
        isOpenAddFile={isOpenAddFile}
        typeFile={typeFile}
        isLoading={isLoading}
        isLoadingOnFetch={isLoadingOnFetch}
        isOpenAddGroupFiles={isOpenAddGroupFiles}
        defaultTitle={defaultTitle}
        defaultDescription={defaultDescription}
        isContentGroupFilesModal={isContentGroupFilesModal}
        activeGroup={activeGroup}
        selectedFilesIds={selectedFilesIds}
        canEditSelected={canEditSelected}
        isAssetTableView={this.props.isAssetTableView}
        isConfirmModalOpen={isConfirmModalOpen}
        isConfirmSelectedModalOpen={isConfirmSelectedModalOpen}
        isShareModalOpen={isShareModalOpen}
        handleInputChange={this.handleInputChange}
        handleRename={this.handleRename}
        handleInputBlur={this.handleInputBlur}
        discardChange={this.discardChange}
        openSectionItem={this.openSectionItem}
        toggleInput={this.toggleInput}
        handleOpenOptions={this.handleOpenOptions}
        handleCloseMoreOption={this.handleCloseMoreOption}
        handleDeleteFile={this.handleDeleteFile}
        handleOpenModalAddFile={this.handleOpenModalAddFile}
        handleChangeToGroup={this.handleChangeToGroup}
        handleCloseAddFile={this.handleCloseAddFile}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        handleSaveValueFromModal={this.handleSaveValueFromModal}
        handleFileSave={this.handleFileSave}
        handleError={this.handleError}
        createFileDoc={this.createFileDoc}
        isModalWindowOpen={this.isModalWindowOpen}
        closeFilesSuccessModal={this.closeFilesSuccessModal}
        deleteFiles={this.deleteFiles}
        handleModalAddGroupFiles={this.handleModalAddGroupFiles}
        onClickAddMoreFiles={this.onClickAddMoreFiles}
        handleSelectAllFiles={this.handleSelectAllFiles}
        handleSelectFile={this.handleSelectFile}
        handleShareClose={this.handleShareClose}
        setNewWizardStepFunc={this.props.setNewWizardStep}
        wizardState={this.props.wizardState}
        _nameInputRef={this._nameInputRef}
        _openRef={this._openRef}
      />
    );
  }
}
const selectActiveCategory = ({
  dashboardState,
  categoriesState,
  projectState
}: IStore) => {
  return dashboardState.activeTab === 'projects'
    ? projectState.activeProject
    : categoriesState.activeCategory;
};

const mapStateToProps = (state: IStore, { sectionId }: IOwnProps) => {
  const files = getFilesBySectionId(state.filesState, sectionId);
  const filesInGroup = getFilesByGroupId(
    state.filesState,
    state.filesState.activeGroup && state.filesState.activeGroup.id
  );
  return {
    accessToken: state.auth.profile.data.accessToken,
    activeCategory: selectActiveCategory(state),
    activeFileFromState: state.filesState.activeFile,
    activeFileTagFilter: state.categoriesState.fileTagFilter,
    activeGroup: state.filesState.activeGroup,
    activeIds: state.filesState.activeIds,
    errors: state.filesState.errors,
    files,
    filesInGroup,
    isAssetTableView: state.auth.profile.preferences?.assetTableView,
    isLoading: state.filesState.isLoading,
    isLoadingOnFetch: state.filesState.isLoadingOnFetch,
    isSelectFromDrive: state.filesState.isSelectFromDrive,
    modalWindows: state.modalWindowState.modalWindows,
    newFileId: state.filesState.newFileId,
    remoteCount: state.filesState.counts[sectionId],
    sections: state.sectionsState.sections,
    wizardState: state.wizardState,
  };
};

const actions = {
  changeToGroup,
  clearActiveGroup,
  closeModalWindow,
  createByUrl,
  createDocFile,
  createFromDrive,
  createSection,
  deleteFiles,
  fetchFiles,
  getFileById,
  handleSelectFromDriveModal,
  openModalWindow,
  requestTokenUpdate,
  setActiveIds,
  setActiveWizardStep,
  setNewWizardStep,
  toggleNewFileWindow,
  updateFile,
  uploadFile,
};

export default connect(mapStateToProps, actions)(Files);
