enum Types {
  SET_TOKEN = '[Set Token] Request',
  USER_ROUTE = '[Auth] User route',
  USER_LOGOUT = '[Auth] Logout',

  ACCESS_VIA_LINK = '[Users] Access via link',
  ACCESS_VIA_LINK_SUCCESS = '[Users] Access via link success',
  ACCESS_VIA_LINK_ERROR = '[Users] Access via link error',

  SET_TOKEN_OUTDATED = '[Token] Request token update',
  SET_ACCOUNT_DEACTIVATED = '[Auth] Set account deactivated',

  FETCH_USERS_REQUEST = '[Users] Fetch users request',
  FETCH_USERS_SUCCESS = '[Users] Fetch users success',

  FETCH_USERS_EMAIL_REQUEST = '[Users] Fetch users email request',
  FETCH_USERS_EMAIL_SUCCESS = '[Users] Fetch users email success',

  PROFILE_REQUEST = '[Profile] Request',
  PROFILE_SUCCESS = '[Profile] Success',

  CHANGE_PREFERENCE = '[Change Preference] Request',

  CREATE_COMPANY_REQUEST = '[Create company] Request',
  CREATE_COMPANY_SUCCESS = '[Create company] Success',

  CLOSE_ACCOUNT_REQUEST = '[Auth] Close account request',
  CLOSE_ACCOUNT_SUCCESS = '[Auth] Close account success',

  INVITE_USERS_REQUEST = '[Users] Invite users request',
  INVITE_USERS_SUCCESS = '[Users] Invite users success',
  INVITE_USERS_ERROR = '[Users] Invite users error',
  INVITE_USERS_ALLOWED = '[Users] Invite users allowed',
  INVITE_USERS_NOT_ALLOWED = '[Users] Invite users allowed',

  REINVITE_USER_REQUEST = '[Users] Reinvite user request',
  REINVITE_USER_SUCCESS = '[Users] Reinvite user success',

  SWITCH_USER_STATUS_REQUEST = '[Users] Switch user status request',
  SWITCH_USER_STATUS_SUCCESS = '[Users] Switch user status success',
  SWITCH_USER_STATUS_ERROR = '[Users] Switch user status error',

  SWITCH_USER_ROLE_REQUEST = '[Users] Switch user role request',
  SWITCH_USER_ROLE_SUCCESS = '[Users] Switch user role success',
  SWITCH_USER_ROLE_ERROR = '[Users] Switch user role error',

  SHARE_CATEGORY_REQUEST = '[Users] Share category request',
  SHARE_CATEGORY_SUCCESS = '[Users] Share category success',
  SHARE_CATEGORY_ERROR = '[Users] Share category error',

  SHARE_INVITED_CATEGORY_REQUEST = '[Users] Share invite category request',
  SHARE_INVITED_CATEGORY_SUCCESS = '[Users] Share invite category success',
  SHARE_INVITED_CATEGORY_ERROR = '[Users] Share invite category error',

  CLEAR_CATEGORY_REQUEST = '[Users] Clear category request',
  CLEAR_CATEGORY_SUCCESS = '[Users] Clear category success',
  CLEAR_CATEGORY_ERROR = '[Users] Clear category error',

  CLEAR_INVITED_CATEGORY_REQUEST = '[Users] Clear invite category request',
  CLEAR_INVITED_CATEGORY_SUCCESS = '[Users] Clear invite category success',
  CLEAR_INVITED_CATEGORY_ERROR = '[Users] Clear invite category error',

  DELETE_USER_REQUEST = '[Users] Delete user request',
  DELETE_USER_SUCCESS = '[Users] Delete user success',
  DELETE_USER_ERROR = '[Users] Delete user error',

  AUTH_ERROR = '[Auth] Auth error',

  CLEAR_INVITE_ERROR = '[Users] Clear invite error',

  CLEAR_SHARE_ERROR = '[Users] Clear share error',

  SET_INVITED_COMPANY_ID = '[Auth] Set invited company id',

  SET_SHARED_LINK = '[Auth] Set shared link',

  SET_CREATE_ACTION_ID = '[Auth] Set action id for create',

  SET_USER_LIST_STATUS = '[Users] Switch user list status',

  SET_NEW_USER = '[Auth] Set new user'
}

export default Types;
