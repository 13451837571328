enum Types {
  FETCH_SECTIONS_REQUEST = '[Sections] Fetch request',
  FETCH_SECTIONS_SUCCESS = '[Sections] Fetch success',
  FETCH_SECTIONS_ERROR = '[Sections] Fetch error',

  FILTER_FILES_IN_SECTION_BY_TAGS = '[Sections] Filter files in section by tags',
  FILTER_FILES_IN_SECTION_BY_TAGS_SUCCESS = '[Sections] Filter files in section by tags success',
  FILTER_FILES_IN_SECTION_BY_TAGS_ERROR = '[Sections] Filter files in section by tags error',

  CREATE_SECTION_REQUEST = '[Section] Create request',
  CREATE_SECTION_SUCCESS = '[Section] Create success',

  UPDATE_SECTION_REQUEST = '[Section] Update request',
  UPDATE_SECTION_SUCCESS = '[Section] Update success',
  UPDATE_SECTION_ERROR = '[Section] Update error',

  DELETE_SECTION_REQUEST = '[Section] Delete request',
  DELETE_SECTION_SUCCESS = '[Section] Delete success',
  DELETE_SECTION_ERROR = '[Section] Delete error',

  FETCH_TEMP_SECTIONS_REQUEST = '[Sections] Fetch temp request',
  FETCH_TEMP_SECTIONS_SUCCESS = '[Sections] Fetch temp success',

  SECTIONS_ERROR = '[Sections] Error',

  TOGGLE_SECTION_VISIBILITY = '[Sections] Toggle section visibility'
}

export default Types;
