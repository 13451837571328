import Types from './types';
import CategoryTypes from '../categories/types';

const initialState: ISectionsState = {
  collapsedSections: {},
  errors: [],
  isLoading: false,
  isLoadingOnFetch: false,
  pendingDeleteId: '',
  sections: new Array<ISection>(),
  tempSections: new Array<ISection>(),
  updateName: ''
};

export default (state: ISectionsState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.FETCH_SECTIONS_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingOnFetch: true
      };
    }

    case Types.FILTER_FILES_IN_SECTION_BY_TAGS: {
      return {
        ...state,
        errors: [],
        isLoading: true,
        isLoadingOnFetch: false
      };
    }

    case Types.FILTER_FILES_IN_SECTION_BY_TAGS_ERROR:
    case Types.FILTER_FILES_IN_SECTION_BY_TAGS_SUCCESS:
    case Types.UPDATE_SECTION_SUCCESS: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingOnFetch: false
      };
    }

    case Types.CREATE_SECTION_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingOnFetch: true
      };
    }

    case Types.TOGGLE_SECTION_VISIBILITY: {
      const collapsedSections = { ...state.collapsedSections };

      collapsedSections[action.payload.id] = action.payload.isCollapsed;

      return {
        ...state,
        collapsedSections
      };
    }

    case Types.UPDATE_SECTION_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: true
      };

      const section = newState.sections.find(
        (sec) => sec.id === action.payload.id
      );
      newState.pendingDeleteId = section.id;
      newState.updateName = section.name;

      newState.sections.find((sec) => sec.id === action.payload.id).name =
        action.payload.name;

      return newState;
    }

    case Types.DELETE_SECTION_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: true,
        isLoadingOnFetch: true,
        pendingDeleteId: action.payload
      } as ISectionsState;
    }

    case Types.UPDATE_SECTION_ERROR: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false
      };

      newState.sections.find(
        (sec) => sec.id === newState.pendingDeleteId
      ).name = newState.updateName;

      newState.pendingDeleteId = '';
      newState.updateName = '';

      return newState;
    }

    case Types.SECTIONS_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };
    }

    case Types.DELETE_SECTION_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false,
        pendingDeleteId: ''
      };
    }

    case CategoryTypes.CREATE_CATEGORY_SUCCESS:
      return {...state, sections: []};

    case Types.FETCH_SECTIONS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false
      };
      newState.sections = action.payload as ISection[];
      newState.sections.reverse();
      return newState;
    }

    case Types.FETCH_SECTIONS_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false,
        sections: []
      };
    }

    case Types.FETCH_TEMP_SECTIONS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false
      };
      newState.tempSections = action.payload as ISection[];
      return newState;
    }

    case Types.CREATE_SECTION_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false,
        sections: [...state.sections]
      };
      const section = action.payload as ISection;
      if (section.id) {
        newState.sections.unshift(action.payload);
      }

      return newState;
    }

    case Types.DELETE_SECTION_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false,
        sections: [...state.sections]
      };

      newState.sections = newState.sections.filter(
        (s) => s.id !== state.pendingDeleteId
      );
      newState.pendingDeleteId = '';

      return newState;
    }

    default:
      return state;
  }
};
