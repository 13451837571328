exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wrapper__2B1KKYXd {\n  height: 100vh;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  -ms-flex-align: center;\n  align-items: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  background: white;\n  color: #424242;\n  text-transform: uppercase; }\n  .wrapper__2B1KKYXd .goHome__15LwAtDK {\n    text-transform: uppercase; }\n\n.errorCode__34K-LFeY {\n  font-size: 10rem;\n  margin: 0 10px;\n  font-weight: 400; }\n\n.title__EnfBWKU2 {\n  margin: 0;\n  margin-bottom: 25px;\n  font-size: 30px;\n  font-weight: 400; }\n\n.errorMessage__2_J58LZJ {\n  margin: 0; }\n\n.goHomeLink__3_kXyClU {\n  margin-top: 30px; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "wrapper__2B1KKYXd",
	"goHome": "goHome__15LwAtDK",
	"errorCode": "errorCode__34K-LFeY",
	"title": "title__EnfBWKU2",
	"errorMessage": "errorMessage__2_J58LZJ",
	"goHomeLink": "goHomeLink__3_kXyClU"
};